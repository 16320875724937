<template>
  <div class="vld-parent">
    <loading :active.sync="isLoading" :can-cancel="false" />
    <CCard>
      <CCardHeader>
        <slot name="header">
          <CIcon name="cil-flag-alt" /> Game Flags
          <div class="card-header-actions">
            <small class="text-muted">Total: {{ flags.length }}</small>
          </div>
        </slot>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          :items="flags"
          :fields="flagFields"
          :hover="hover" >

          <template #gameFlag="{ item }">
              <td class="py-2">
                  <div v-if="item.bitShift === 4">Reserved for Full Client Identifier</div>
                  <div v-else-if="item.bitShift === 5">Reserved for Active Subscription Identifier</div>
                  <CInput v-else v-model="item.flag" />
              </td>
          </template>

          <template #update="{ item }">
            <td class="py-2">
              <CButton
                v-if="item.bitShift != 4 && item.bitShift != 5"
                color="primary"
                variant="outline"
                square
                size="sm"
                @click="updateFlag(item)" >
                Update
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Flags",
  components: {
    Loading,
  },
  data() {
    return {
      flags: [],
      flagFields: [
        { key: "bitShift", label: "", filter: false, sorter: false },
        { key: "gameFlag", label: "Flag", filter: false, sorter: false },
        { key: "update", label: "", filter: false, sorter: false },
      ],
      hover: true,
      isLoading: false,
    };
  },
  methods: {
    getFlags() {
      this.isLoading = true;
      this.$http
        .get(this.$config.gmapi + "/gameflag", {
          headers: this.$config.getHeaders(),
        })
        .then((response) => {
          return response.json();
        })
        .then((jsonData) => {
          this.flags = jsonData;
        })
        .catch(function (error) {
          this.isLoading = false;
          this.$root.$emit('onErr', error);
        })
        .finally(function () {
          this.isLoading = false;
        });
    },
    updateFlag(item) {
        this.isLoading = true;
        this.$http.put(this.$config.gmapi + '/gameflag', item, {headers: this.$config.getHeaders() }).then(response => {
            return response.json();
        }).then(jsonData => {
            this.getFlags();
        }).catch(function (error) {
          this.$root.$emit('onErr', error);
            this.isLoading = false;
        }); 
    }
  },
  created() {
    this.getFlags();
  },
};
</script>

<style scoped>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
